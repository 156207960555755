import "../css/app.pcss";

/* =============================================================================
Code For Canada custom JS
============================================================================= */

/* Disable empty hash links, meant to go nowhere.
=================================================== */
document.querySelectorAll('a[href="#"]').forEach(function(link) {
  link.addEventListener('click', function(ev) {
    ev.preventDefault();
  } );
});

/* Flag document with class when scrolled down for sticky header styling.
=================================================== */
if (
  'IntersectionObserver' in window &&
  'IntersectionObserverEntry' in window &&
  'intersectionRatio' in window.IntersectionObserverEntry.prototype
) {
  new IntersectionObserver(entries => {
    document.documentElement.classList.toggle(
      'header-shrunk',
      entries[0].boundingClientRect.y < 0
    );
  }).observe(document.querySelector('.scroll-marker'));
}


/* Fix Google Maps embedded iframe scroll behaviour.
=================================================== */
// Only add this functionality for non-touch devices.
if (window.matchMedia('(hover: hover)').matches) {
  document.querySelectorAll('iframe[src*="google.com/maps/d/embed"]').forEach(function(iframe) {
    var wrapper = iframe.parentNode;
    var overlay = wrapper.querySelector('.responsive-container__overlay');

    // Disable iframe's pointer events by default.
    iframe.classList.add('pointer-events-none');

    // Enable iframe's pointer events when clicked.
    overlay.addEventListener('mousedown', () => {
      iframe.classList.remove('pointer-events-none');
      overlay.classList.add('responsive-container__overlay--active');
    });

    // Disable iframe's pointer events when mouse up or leaving the iframe.
    iframe.addEventListener('mouseup', () => {
      iframe.classList.add('pointer-events-none');
      overlay.classList.remove('responsive-container__overlay--active');
    });
    iframe.addEventListener('mouseleave', () => {
      iframe.classList.add('pointer-events-none');
      overlay.classList.remove('responsive-container__overlay--active');
    });
  });
}
